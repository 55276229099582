<template>
    <div class="fill-height">
       <!-- Loading icon -->
        <div v-if="loading" class="fill-height d-flex flex-row justify-center align-center">
            <v-progress-circular
                :size="70"
                :width="7"
                color="purple darken-3"
                indeterminate
            />
        </div>
        <!-- END Loading icon -->

        <!-- Error -->
        <div
            v-if="!_.isNil(error)"
            class="fill-height d-flex flex-column justify-center align-center"
        >
            <v-alert type="error">
                {{ error }}
            </v-alert>
            <!-- <external-login-btn @error="onExternalLoginError" /> -->
        </div>
        <!-- END Error -->

        <!-- View -->
        <iframe
            v-if="hasUrl"
            ref="iframe"
            :src="customUrl"
            scrolling="no" 
            frameborder="no"
            class="vw-100 vh-100"
            @load="onFrameLoaded"
        ></iframe>
       <!-- END View -->

        <v-scroll-y-reverse-transition>
            <v-alert
                v-if="isInfoAlertVisible"
                type="info"
                class="alert-info"
            >
                Информация в данном ЦОК является полностью достоверной по состоянию на 19 сентября 2022 г..
            </v-alert>
        </v-scroll-y-reverse-transition>
    </div>
</template>

<script>
// import ExternalLoginBtn from '@/components/other/ExternalLoginBtn.vue'
import IframeConnectionMixin from '@/mixins/IframeConnectionMixin.vue'

export default {
    name: 'DigitalContentView',
    mixins: [IframeConnectionMixin],
    // components: {
    //     ExternalLoginBtn
    // },
    data () {
        return {
            loading: true,
            error: null,
            data: null,
            apiEmulate: false,
            isInfoAlertVisible: false
        }
    },
    computed: {
        externalId () {
            return _.get(this.$route, 'params.externalId', '');
        },
        isEducontUser () {
            return this.$store.getters['user/isEducontUser'];
        },
        hasUrl() {
            return _.size(_.get(this.data, 'url', '')) > 0;
        },
        customUrl() {
            if(!this.hasUrl)
                return null;
            const extraParams = [
                    // eslint-disable-next-line no-unexpected-multiline
                        `isSchoolbook=${_.get(this, 'data.data.preview.isSchoolbook', '').toString()}`,
                        `showSidebar=${_.get(this, 'data.data.preview.showSidebar', '').toString()}`
                    ]
                    .join('&')
			return _.join([this.data.url, extraParams], this.data.courseTypeId > 0 ? '&' : '?');
		}
    },
    async created () {
        const { data, error } = await this.$store.dispatch('app/view', {
            externalId: this.externalId,
            access_token: this.$store.state.user.access_token || '',
            apiEmulate: this.apiEmulate ? 'y' : ''
        });

        this.data = data;
        this.error = error;

        this.loading = false;
        
        // Благодаря проверке на this.isEducontUser отправка статистики для входа
        // из под привилегированного аккаунта не произойдет.
        if (this.isEducontUser && !error && this.hasUrl) {
            this.$store.dispatch('app/statsCreate', {
                externalId: this.externalId,
                type: 'ENTER_TO_STUDY_SUBJECT'
            });
            this.showInfoAlert();
        }
    },
    methods: {
        onExternalLoginError (err) {
            this.error = err
        },
        async showInfoAlert () {
            await this.sleep(1000);
            this.isInfoAlertVisible = true;
            await this.sleep(5000);
            this.isInfoAlertVisible = false;
        },
        sleep (timeout) {
            return new Promise((resolve) => {
                setTimeout(() => { resolve(true) }, timeout)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
#app-container {
    .alert-info {
        position: fixed;
        z-index: 999;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        border: 1px solid #2e9cdb!important;
        background-color: #2e9cdb!important;
        color: white;
        font-size: 16px;
    }
}
</style>